<html lang="html">
	<body>
		<span class="loader" />
	</body>
</html>

<style>
	* {
		box-sizing: border-box;
	}
	html,
	body {
		margin: 0;
		padding: 0;
		width: 100vw;
		height: 100vh;
	}

	body {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.loader {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		position: absolute;
		z-index: 1000;
		animation: rotate 1s linear infinite;
		margin: 0;
		padding: 0;
	}
	.loader::before,
	.loader::after {
		content: '';
		box-sizing: border-box;
		position: absolute;
		inset: 0px;
		border-radius: 50%;
		border: 5px solid #aa9173;
		animation: prixClipFix 2s linear infinite;
	}
	.loader::after {
		border-color: #c1aa8e;
		animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
		inset: 6px;
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes prixClipFix {
		0% {
			clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
		}
		25% {
			clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
		}
		50% {
			clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
		}
		75% {
			clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
		}
		100% {
			clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
		}
	}
</style>
