<script>
	import { page } from '$app/stores';
	import { fly } from 'svelte/transition';
	import { onMount } from 'svelte';
	import { status, language } from '$lib/stores/store.js';
	import LanguageSwitch from './LanguageSwitch.svelte';

	let isOpenMenu = false;

	/**
	 * 
	 * 
	 * 
	 * {#if $language == 'DE'}
			{fetchedValues.aboutMeData.MyHeader}
		{:else if $language == 'EN'}
			{fetchedValues.aboutMeData.localizations.data[1].attributes.MyHeader}
		{:else if $language == 'RU'}
			{fetchedValues.aboutMeData.localizations.data[0].attributes.MyHeader}
		{/if}
	 * 
	 * 
	 */

	const openMenu = (status) => {
		if (status) {
			isOpenMenu = !isOpenMenu;
		}

		if (isOpenMenu) {
			document.body.style.overflow = 'hidden';
			document.querySelector('.menu').style.transform = 'rotate(90deg)';
		} else {
			document.body.style.overflow = 'auto';
			document.querySelector('.menu').style.transform = 'rotate(0)';
		}
	};
</script>

<nav>
	{#if isOpenMenu}
		<!--<LanguageSwitch />-->
	{/if}
	<div class="menu" on:click={openMenu($status)} on:keydown={openMenu($status)}>
		<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path
				xmlns="http://www.w3.org/2000/svg"
				d="M4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7ZM4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12ZM4 17C4 16.4477 4.44772 16 5 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H5C4.44772 18 4 17.5523 4 17Z"
				fill="#ffffff"
			/>
		</svg>
	</div>

	<a class="" href={$status ? '/' : '#'} data-sveltekit-preload-data="hover">
		<div class="logo" />
	</a>
</nav>

{#if isOpenMenu}
	<div
		transition:fly={{ y: 0, duration: 500 }}
		class="naxBox w-screen h-screen fixed flex justify-center align-center items-center backdrop-blur-md bg-black bg-opacity-80"
	>
		<ul class="">
			<li>
				<a
					class:active={$page.url.pathname === '/'}
					on:click={openMenu}
					data-sveltekit-preload-data="hover"
					aria-current="page"
					href="/">Home</a
				>
			</li>
			<li>
				<a
					class:active={$page.url.pathname === '/leistungen'}
					on:click={openMenu}
					data-sveltekit-preload-data="hover"
					href="/leistungen">Leistungen</a
				>
			</li>
			<li>
				<a
					class:active={$page.url.pathname === '/immobilien'}
					on:click={openMenu}
					data-sveltekit-preload-data="hover"
					href="/immobilien">Immobilien</a
				>
			</li>
			<li>
				<a
					class:active={$page.url.pathname === '/kontakt'}
					on:click={openMenu}
					data-sveltekit-preload-data="hover"
					href="/kontakt">Kontakt</a
				>
			</li>
			<li />
		</ul>

		<div class="hidden lg:block ml-auto" />
	</div>
{/if}

<style>
	:root {
		--primary-color: #aa9173;
	}
	nav {
		height: 15vh;
		max-height: 200px;
		z-index: 100;
		position: absolute;
		display: flex;
		align-items: center;
		left: 0;
		right: 0;
		justify-content: center;
	}
	nav a {
		transition: all 200ms ease-in-out;
		width: 5em;
		height: 5em;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0rem;
		padding: 2rem;
		overflow: hidden;
		background: radial-gradient(circle, rgba(255, 255, 255, 0.15) 10%, rgba(255, 255, 255, 0) 70%);
	}
	.logo {
		width: 200%;
		height: 200%;
		background: var(--logo);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		filter: brightness(1.2);
	}

	.naxBox {
		z-index: 99;
	}
	ul {
		width: 100%;
		text-align: center;
		display: flex row;
		justify-content: center;
		align-items: center;
	}
	li {
		padding: 1rem 0;
	}
	.active {
		text-decoration: underline;
		text-underline-offset: 1rem;
	}
	.menu {
		transition: all 200ms ease-in-out;
		width: max(3vw, 30px);
		max-width: 50px;
		position: absolute;
		right: 2rem;
	}
	.menu svg {
		filter: drop-shadow(1px 1px 25px rgb(0, 0, 0));
	}
	a {
		color: var(--primary-color);
		font-size: min(7vw, 3rem);
	}

	@media screen and (max-width: 500px) {
		nav a {
			transition: all 200ms ease-in-out;
			width: 5em;
			height: 5em;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: 0rem;
			padding: 1rem;
			overflow: hidden;
		}
	}
</style>
