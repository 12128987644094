<script>
	import { navigating } from '$app/stores';
	import '../app.css';
	import Footer from '$lib/components/Footer.svelte';
	import Navigation from '$lib/components/Navigation.svelte';
	import CookieBanner from '$lib/components/CookieBanner.svelte';
	import Spinner from '$lib/components/Spinner.svelte';
	import { status } from '$lib/stores/store.js';
	import { browser } from '$app/environment';
	import '@fontsource/titillium-web/200.css';
	import '@fontsource/titillium-web/300.css';
	import '@fontsource/titillium-web/400.css';
	import '@fontsource/titillium-web/600.css';
</script>

<svelte:head>
	<script type="text/javascript">
		var _iub = _iub || [];
		_iub.csConfiguration = {"siteId":3370238,"cookiePolicyId":46665247,"lang":"de"};
		</script>
		<script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3370238.js"></script>
		<script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
	<meta name="viewport" content="width=device-width, initial-scale=1" />
</svelte:head>

<main class="w-full h-full">
	{#if $navigating}
		<Spinner />
	{/if}
	<Navigation />
	<slot />
	<Footer />
</main>

<style>
	* {
		box-sizing: border-box;
		font-family: 'Titillium Web', sans-serif !important;
		font-weight: 200;
	}
</style>
